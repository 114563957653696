import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import styles, { colors, windowTagStyle } from '../styles/global'
import Icon from '../Icon'

export default function TimeTag({ time, containerStyle, onPress }) {
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)

    const { startDate, endDate } = time
    const hideStartDate = isMiddleScreen || smallScreenNavigation

    const text = hideStartDate
        ? `${endDate.format('HH:mm')}`
        : `${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`

    return (
        <TouchableOpacity onPress={onPress} style={[localStyles.container, containerStyle]}>
            <Icon name={'calendar'} size={16} color={colors.Text03} style={localStyles.icon} />
            <Text style={[localStyles.text, windowTagStyle()]}>{text}</Text>
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: colors.Gray300,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
    },
    icon: {
        marginHorizontal: 4,
    },
    text: {
        ...styles.subtitle2,
        color: colors.Text03,
        marginVertical: 1,
        marginRight: 10,
        marginLeft: 2,
    },
})
