import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import MyDayTasksList from './MyDayTasksList'
import MyDayDateLine from './MyDayDateLine'

export default function MyDayOtherTasks() {
    const myDayOtherTasks = useSelector(state => state.myDayOtherTasks)

    const dates = []
    const taskByDate = {}

    myDayOtherTasks.forEach(task => {
        const { estimatedDateFormated } = task
        if (!taskByDate[estimatedDateFormated]) {
            dates.push(estimatedDateFormated)
            taskByDate[estimatedDateFormated] = []
        }
        taskByDate[estimatedDateFormated].push(task)
    })

    return (
        <>
            {dates.map(date => {
                const tasks = taskByDate[date]
                return (
                    <Fragment key={date}>
                        <MyDayDateLine tasks={tasks} date={date} containerStyle={{ marginTop: 16 }} />
                        <MyDayTasksList tasks={tasks} date={date} />
                    </Fragment>
                )
            })}
        </>
    )
}
