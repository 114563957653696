import moment from 'moment'

import { getDb, globalWatcherUnsub, mapTaskData } from '../firestore'
import { FEED_PUBLIC_FOR_ALL } from '../../../components/Feeds/Utils/FeedsConstants'
import { setMyDayAllTodayTasks } from '../../../redux/actions'
import store from '../../../redux/store'
import Colors from '../../../Themes/Colors'
import { objectIsLockedForUser } from '../../../components/Guides/guidesHelper'

export const TO_ATTEND_TASKS_MY_DAY_TYPE = 'TO_ATTEND_TASKS_MY_DAY_TYPE'
export const OBSERVED_TASKS_MY_DAY_TYPE = 'OBSERVED_TASKS_MY_DAY_TYPE'
export const WORKSTREAM_TASKS_MY_DAY_TYPE = 'WORKSTREAM_TASKS_MY_DAY_TYPE'

export function mapProjectLowColor(color) {
    const map = {
        [Colors.Marker_Blue_01]: Colors.Marker_Blue_Low,
        [Colors.Marker_Violet_01]: Colors.Marker_Violet_Low,
        [Colors.Marker_Orange_01]: Colors.Marker_Orange_Low,
        [Colors.Marker_Pelorous_01]: Colors.Marker_Pelorous_Low,
        [Colors.Marker_Yellow_01]: Colors.Marker_Yellow_Low,
        [Colors.Marker_Green_01]: Colors.Marker_Green_Low,
        [Colors.Marker_Pink_01]: Colors.Marker_Pink_Low,
        [Colors.Marker_Red_01]: Colors.Marker_Red_Low,
        [Colors.Marker_Lime_01]: Colors.Marker_Lime_Low,
        [Colors.Marker_Purple_01]: Colors.Marker_Purple_Low,
        [Colors.Marker_PrimaryBlue_01]: Colors.Marker_PrimaryBlue_Low,
    }

    return map[color] || Colors.Marker_Blue_Low
}

function addTaskToContainers(tasks, subtasksMap, task) {
    const { parentId } = task
    if (parentId) {
        subtasksMap[parentId] ? subtasksMap[parentId].push(task) : (subtasksMap[parentId] = [task])
    } else {
        tasks.push(task)
    }
}

export async function watchTasksToAttend(projectId, userId, watcherKey) {
    const endOfDay = moment().endOf('day').valueOf()

    globalWatcherUnsub[watcherKey] = getDb()
        .collection(`items/${projectId}/tasks`)
        .where('inDone', '==', false)
        .where('currentReviewerId', '==', userId)
        .where('dueDate', '<=', endOfDay)
        .orderBy('dueDate', 'desc')
        .onSnapshot(docs => {
            const tasks = []
            const subtasksMap = {}

            docs.forEach(doc => {
                const task = mapTaskData(doc.id, doc.data())

                const { loggedUser } = store.getState()
                const { unlockedKeysByGuides } = loggedUser
                const isLocked = objectIsLockedForUser(projectId, unlockedKeysByGuides, task.lockKey, loggedUser.uid)

                if (!isLocked) {
                    task.projectId = projectId
                    addTaskToContainers(tasks, subtasksMap, task)
                }
            })
            store.dispatch(setMyDayAllTodayTasks(projectId, TO_ATTEND_TASKS_MY_DAY_TYPE, '', tasks, subtasksMap))
        })
}

export async function watchObservedTasks(projectId, userId, watcherKey) {
    const endOfDay = moment().endOf('day').valueOf()

    globalWatcherUnsub[watcherKey] = getDb()
        .collection(`items/${projectId}/tasks`)
        .where(`dueDateByObserversIds.${userId}`, '<=', endOfDay)
        .onSnapshot(docs => {
            const tasks = []
            const subtasksMap = {}

            docs.forEach(doc => {
                const task = mapTaskData(doc.id, doc.data())
                const { inDone, isPublicFor } = task
                if (!inDone && (isPublicFor.includes(FEED_PUBLIC_FOR_ALL) || isPublicFor.includes(userId))) {
                    task.projectId = projectId
                    addTaskToContainers(tasks, subtasksMap, task)
                }
            })
            store.dispatch(setMyDayAllTodayTasks(projectId, OBSERVED_TASKS_MY_DAY_TYPE, '', tasks, subtasksMap))
        })
}

export async function watchWorkstreamTasks(projectId, userId, workstreamId, watcherKey) {
    const endOfDay = moment().endOf('day').valueOf()

    const allowUserIds = [FEED_PUBLIC_FOR_ALL, userId]

    globalWatcherUnsub[watcherKey] = getDb()
        .collection(`items/${projectId}/tasks`)
        .where('inDone', '==', false)
        .where('currentReviewerId', '==', workstreamId)
        .where('isPublicFor', 'array-contains-any', allowUserIds)
        .where('dueDate', '<=', endOfDay)
        .orderBy('dueDate', 'desc')
        .onSnapshot(docs => {
            const tasks = []
            const subtasksMap = {}

            docs.forEach(doc => {
                const task = mapTaskData(doc.id, doc.data())
                task.projectId = projectId
                addTaskToContainers(tasks, subtasksMap, task)
            })
            store.dispatch(
                setMyDayAllTodayTasks(projectId, WORKSTREAM_TASKS_MY_DAY_TYPE, workstreamId, tasks, subtasksMap)
            )
        })
}
