import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles, { colors, windowTagStyle } from '../../styles/global'
import Icon from '../../Icon'
import { translate } from '../../../i18n/TranslationService'

export default function TalkToAssistant({ openModal }) {
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)

    return (
        <TouchableOpacity style={[localStyles.tag, smallScreenNavigation && localStyles.tagMobile]} onPress={openModal}>
            <View style={localStyles.icon}>
                <Icon name={'cpu'} size={16} color={colors.Text03} />
            </View>
            {!smallScreenNavigation && (
                <Text style={[styles.subtitle2, localStyles.text, windowTagStyle()]}>
                    {translate('Chat with assistant')}
                </Text>
            )}
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    tag: {
        flexDirection: 'row',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        alignSelf: 'flex-start',
        borderWidth: 1,
        borderColor: colors.Text03,
        paddingHorizontal: 4,
    },
    tagMobile: {
        width: 24,
        height: 24,
    },
    text: {
        color: colors.Text03,
        marginLeft: 6,
        marginRight: 4,
    },
    icon: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
})
