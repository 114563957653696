import React from 'react'
import { useSelector } from 'react-redux'

import MyDayTasksList from './MyDayTasksList'
import MyDayDateLine from './MyDayDateLine'
import moment from 'moment'

export default function MyDaySelectedTasks() {
    const myDaySelectedTasks = useSelector(state => state.myDaySelectedTasks)

    return (
        <>
            <MyDayDateLine tasks={myDaySelectedTasks} date={moment().format('YYYYMMDD')} />
            <MyDayTasksList tasks={myDaySelectedTasks} />
        </>
    )
}
