import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import ProjectHelper from '../../SettingsView/ProjectsSettings/ProjectHelper'
import store from '../../../redux/store'
import MyDayTasksProjectLoaders from './MyDayTasksProjectLoaders'
import { clearMyDayAllTodayTasks, setMyDaySelectedAndOtherTasks } from '../../../redux/actions'
import {
    TIME_FOR_CHECK_ACTIVE_TASK_ESTIMATION,
    processTaskEstimationWhenTimePass,
    selectTasksAndAddTimeIntervale,
} from '../MyDayTasks/myDayHelper'

export default function MyDayTasksLoaders() {
    const dispatch = useDispatch()
    const tasksLoaded = useSelector(state => state.myDayAllTodayTasks.loaded)
    const projectIds = useSelector(state => state.loggedUser.projectIds)
    const activeTaskStartingDate = useSelector(state => state.loggedUser.activeTaskStartingDate)
    const [projectsToLoadIds, setProjectsToLoadIds] = useState([])

    useEffect(() => {
        const { loggedUser, loggedUserProjectsMap } = store.getState()
        const { guideProjectIds, archivedProjectIds, templateProjectIds } = loggedUser

        const sortedLoggedUserProjectIds = ProjectHelper.getNormalAndGuideProjectsSortedByName(
            projectIds,
            guideProjectIds,
            archivedProjectIds,
            templateProjectIds,
            loggedUserProjectsMap,
            false
        )

        setProjectsToLoadIds(sortedLoggedUserProjectIds)
    }, [projectIds])

    useEffect(() => {
        const { loggedUser, myDaySelectedTasks, myDayOtherTasks } = store.getState()

        const { selectedTasks, otherTasks } = selectTasksAndAddTimeIntervale(
            [...myDaySelectedTasks, ...myDayOtherTasks],
            loggedUser,
            activeTaskStartingDate
        )
        dispatch(setMyDaySelectedAndOtherTasks(selectedTasks, otherTasks))
    }, [activeTaskStartingDate])

    useEffect(() => {
        if (tasksLoaded) {
            const intervalId = setInterval(processTaskEstimationWhenTimePass, TIME_FOR_CHECK_ACTIVE_TASK_ESTIMATION)
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [tasksLoaded])

    useEffect(() => {
        return () => {
            dispatch(clearMyDayAllTodayTasks())
        }
    }, [])

    return (
        <View>
            {projectsToLoadIds.map(projectId => {
                return <MyDayTasksProjectLoaders key={projectId} projectId={projectId} />
            })}
        </View>
    )
}
